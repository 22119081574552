import * as React from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../store";

import { useTranslation } from "react-i18next";
import { useInterval } from "../utils";
import GenericLayout from "../components/GenericLayout";

const instructions = [
  ["are-playing", ""],
  ["not-muted", "unmute"],
  ["turn-up-volume", "volume"],
  ["keep-awake", "sleep"],
];

const Waiting = () => {
  const { t } = useTranslation("toucan");
  const { part, amountOfParts } = useSelector(
    ({ part, amountOfParts }: IAppState) => ({
      part,
      amountOfParts,
    })
  );
  const [instructionIndex, setInstructionIndex] = React.useState(0);
  useInterval(() => {
    const next = (instructionIndex + 1) % instructions.length;
    setInstructionIndex(next);
  }, 5000);

  const [key, additionalIcon] = instructions[instructionIndex];
  const text = t(key, { part, amount: amountOfParts });
  return (
    <GenericLayout contentClass={"icon " + additionalIcon} footerText={text} />
  );
};

export default Waiting;
