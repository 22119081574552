import { Tone } from "@smartphoneorchestra/live.js";
import * as Live from "@smartphoneorchestra/live.js";

import FirebaseConnector, { IFirebaseState } from "./firebase/mainframe";

// A class that keeps a Tone.Transport sync to
// an external start time and clock skew
class ToneSync {
  private checkSyncInterval: number | null = null;
  private ticksOffsyncAllowanceRange = 9;
  private startTime = 0;
  private clockOffset = 0;

  constructor() {
    FirebaseConnector.on("change", (data) => {
      const { startTime, state } = data as IFirebaseState;
      if (state === "started") {
        this.start(startTime);
      } else {
        this.stop();
      }
    });
  }

  start(startTime: number) {
    this.startTime = startTime;
    if (Tone.Transport.state === "stopped") {
      Live.stop(0);
      const millisecondsPerTick =
        60000 / Tone.Transport.bpm.value / Tone.Transport.PPQ;
      this.ticksOffsyncAllowanceRange = Math.ceil(50 / millisecondsPerTick);
      if (Tone.Transport.state === "stopped") {
        const timeSinceStart = this.getTimeSinceStart();
        const expectedPosition = Tone.Time(timeSinceStart / 1000);
        const tick = `${expectedPosition.toTicks()}i`;
        Tone.Transport.start(Tone.now(), tick);
      }

      if (this.checkSyncInterval) {
        clearInterval(this.checkSyncInterval);
      }
      setTimeout(() => {
        this.checkSyncInterval = window.setInterval(this.checkSync, 500);
      }, 5000);

      for (const trackName in Live.LiveState.tracks) {
        if (!trackName) {
          continue;
        }
        const track = Live.LiveState.tracks[trackName];
        const clipSlot = track.clipSlots[0];
        const clip = clipSlot.getClip();
        if (clip) {
          clip.start(0);
        }
      }
    }
  }

  stop() {
    Tone.Transport.stop();
    if (this.checkSyncInterval) {
      clearInterval(this.checkSyncInterval);
      this.checkSyncInterval = null;
    }
  }

  setStartTime(startTime: number) {
    this.startTime = startTime;
  }

  setClockOffset(clockOffset: number) {
    this.clockOffset = clockOffset;
  }

  getTimeSinceStart() {
    return this.now() - this.startTime;
  }

  private checkSync = () => {
    const timeSinceStart = this.getTimeSinceStart();
    const expectedPosition = Tone.TransportTime(
      timeSinceStart / 1000
    ).toTicks();
    const actualPosition = Tone.TransportTime().toTicks();
    const diff = expectedPosition - actualPosition;
    const absoluteDifference = Math.abs(diff);
    if (absoluteDifference > this.ticksOffsyncAllowanceRange) {
      Tone.Transport.ticks = expectedPosition;
    }
  };

  private now() {
    return Date.now() + this.clockOffset;
  }
}

export default new ToneSync();
