import { Tone } from "@smartphoneorchestra/live.js";
import { randomElement } from "./toucan/src/utils";

const notificationPlaybackRate = [
  0.793701, 0.890899, 1, 1.122462, 1.259921, 1.414214, 1.587401,
];

const notificationPlayer = new Tone.Player(
  "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/iMessage.mp3"
).toDestination();
const dataSorting = new Tone.Player(
  "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/thegroup3/Test-DataSorting2.mp3"
).toDestination();

const correctSamples = [
  new Tone.Player(
    "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/thegroup3/Emogame/Correct_Answer_1.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/thegroup3/Emogame/Correct_Answer_2.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/thegroup3/Emogame/Correct_Answer_3.mp3"
  ).toDestination(),
];

const wrongSamples = [
  new Tone.Player(
    "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/thegroup3/Emogame/Wrong_Answer_1.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/thegroup3/Emogame/Wrong_Answer_2.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/thegroup3/Emogame/Wrong_Answer_3.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/thegroup3/Emogame/Wrong_Answer_4.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/thegroup3/Emogame/Wrong_Answer_5.mp3"
  ).toDestination(),
];

const happyButton = new Tone.Player(
  "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/thegroup3/Emogame/Happy_Sound.mp3"
).toDestination();

const notificationPlayers = [
  new Tone.Player(
    "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/thegroup3/1SlideSounds/1_b_Slide_Sound.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/thegroup3/1SlideSounds/1_c_Slide_Sound.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/thegroup3/1SlideSounds/1_d_Slide_Sound.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/thegroup3/1SlideSounds/1_d_Slide_Sound.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/thegroup3/1SlideSounds/1_e_Slide_Sound.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/thegroup3/1SlideSounds/1_f_Slide_Sound.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/thegroup3/1SlideSounds/1_g_Slide_Sound.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/thegroup3/1SlideSounds/1_h_Slide_Sound.mp3"
  ).toDestination(),
];

function audioChecks(player: Tone.Player) {
  if (Tone.context.state !== "running" || !player.loaded) {
    return;
  }
  if (player.state === "started") {
    player.restart(Tone.now() + 0.1);
  } else {
    player.start(Tone.now() + 0.1);
  }
}

export const buttonPlayers = [
  new Tone.Player(
    "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/thegroup3/Emogame/Wrong_Answer_5.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/thegroup3/Emogame/Wrong_Answer_5.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/thegroup3/Emogame/Wrong_Answer_5.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://smartphone-orchestra.s3.eu-west-1.amazonaws.com/thegroup3/Emogame/Wrong_Answer_5.mp3"
  ).toDestination(),
];

export function playNotification() {
  notificationPlayer.playbackRate = randomElement(notificationPlaybackRate);
  audioChecks(notificationPlayer);
}

export function playCorrect() {
  const randomPlayer = randomElement(correctSamples);
  if (randomPlayer.buffer.loaded) {
    randomPlayer.start(Tone.now() + 0.01);
  } else {
    randomPlayer.autostart = true;
  }
}

export function playWrong() {
  const randomPlayer = randomElement(wrongSamples);
  if (randomPlayer.buffer.loaded) {
    randomPlayer.start(Tone.now() + 0.01);
  } else {
    randomPlayer.autostart = true;
  }
}

export function playHappy() {
  happyButton.playbackRate = randomElement(notificationPlaybackRate);
  happyButton.start(Tone.now() + 0.01);
}

export function playDataSorting() {
  const dataPlaybackRate = [1.0, 1.25, 1.5, 1.75, 2];
  dataSorting.playbackRate = randomElement(dataPlaybackRate);
  dataSorting.loop = true;
  const reverse = Boolean(Math.round(Math.random()));
  dataSorting.reverse = reverse;
  if (dataSorting.buffer.loaded === true) {
    dataSorting.start(Tone.now() + 0.01);
  } else {
    dataSorting.autostart = true;
  }
}

export function stopDataSorting() {
  dataSorting.stop();
}

export function playRandomNotificationSound() {
  const randomPlayer = randomElement(notificationPlayers);
  if (randomPlayer.buffer.loaded) {
    randomPlayer.start(Tone.now() + 0.01);
  } else {
    randomPlayer.autostart = true;
  }
}

export function playCustomSample(sample: string, vol?: number) {
  const player = new Tone.Player(sample).toDestination();
  player.volume.value = vol ? vol : -12;
  if (player.buffer.loaded) {
    player.start(Tone.now());
  } else {
    player.autostart = true;
  }
  // audioChecks(player);
}
