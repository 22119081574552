import * as React from "react";
import Question from "./Question";
import GenericLayout from "../toucan/src/components/GenericLayout";
import * as Types from "../types";
import Result from "./Result";
import {
  useFirebaseTargetState,
  useFirebaseUserState,
} from "../toucan/src/firebase/hooks";
// import * as Sound from "../sound";

interface QuestionsProps {
  target: string;
}

let questionsStartTime = 0;

const Questions = (props: QuestionsProps) => {
  const [userQuestions, setUserQuestions] =
    useFirebaseUserState<Types.Question[]>("questions");

  // answers in the user's key. We only flush them to the target once
  // the user accepts their answers
  const [userAnswers, setUserAnswers] =
    useFirebaseUserState<string[]>("answers");
  const questions = userQuestions || [];
  const [questionIndex, setQuestionIndex] = React.useState(0);
  const [, setFinalAnswers] = useFirebaseTargetState<string[]>(props.target);
  const [durations, setDurations] =
    useFirebaseTargetState<number[]>("durations");

  React.useEffect(() => {
    // Sound.playNotification();
    questionsStartTime = Date.now();
  }, []);

  const onAccept = () => {
    // Sound.playRandomSound();
    setFinalAnswers(userAnswers || []);
    setUserQuestions([]);
    setUserAnswers([]);
    const currentDurations = durations || [];
    const duration = Date.now() - questionsStartTime;
    setDurations([...currentDurations, duration]);
  };

  const onClick = (choice: Types.Option) => {
    // Sound.playRandomSound();
    const copy = userAnswers || [];
    copy[questionIndex] = choice.value;
    setQuestionIndex(questionIndex + 1);
    setUserAnswers(copy);
  };

  const onPrevious = () => {
    const currentIndex = Math.min(questionIndex, questions.length);
    setQuestionIndex(currentIndex - 1);
  };

  if (!Array.isArray(questions) || questions.length === 0) {
    return <GenericLayout footerText={<span>{"please-wait"}</span>} />;
  }

  if (questionIndex >= questions.length) {
    return <Result onAccept={onAccept} onPrevious={onPrevious} />;
  } else {
    return (
      <Question
        question={questions[questionIndex]}
        onChoice={onClick}
        showPrevious={questionIndex > 0}
        onPrevious={onPrevious}
      />
    );
  }
};

export default Questions;
