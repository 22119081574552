import * as React from "react";
import GenericLayout from "../components/GenericLayout";

import { useTranslation } from "react-i18next";
import { actions } from "../store";

const Ready = () => {
  const { t } = useTranslation("toucan");
  return (
    <GenericLayout
      onClick={actions().startSync}
      contentClass="icon"
      mainText={undefined}
      footerText={<span>{t("click-to-join")}</span>}
    />
  );
};

export default Ready;
