import { Tone } from "@smartphoneorchestra/live.js";

export type StaterListener = (state: number) => void;
// eslint-disable-next-line
const StaterListenerNoOp = (state: number) => {};

type Event = { time: number; value: number };

class Stater {
  events: Event[] = [];
  private id = -1;
  private listener: StaterListener = StaterListenerNoOp;
  private currentState = 0;

  constructor(id: number) {
    (window as any).Staters[id] = this;
    Tone.Transport.on("start", () => {
      Tone.Transport.scheduleRepeat(
        this.checkState.bind(this),
        "4n",
        Tone.Transport.now()
      );
    });
    this.id = id;
  }

  setListener(listener: StaterListener) {
    this.listener = listener;
    if (this.currentState !== null) {
      this.listener(this.currentState);
    }
  }

  unsetListener() {
    this.listener = StaterListenerNoOp;
  }

  private checkState() {
    const ticks = Tone.Transport.ticks;
    const { value } = this.findEventAtTick(ticks);
    if (this.currentState !== value) {
      this.listener(value);
      this.currentState = value;
    }
  }

  private findEventAtTick(tick: number) {
    let lastMatch = { time: -1, value: -1 };
    for (const e of this.events) {
      if (e.time <= tick) {
        lastMatch = e;
      } else {
        break;
      }
    }
    return lastMatch;
  }
}

export function registerListenerForStaterWithId(
  id: number,
  listener: StaterListener
) {
  if ((window as any).Staters[id]) {
    (window as any).Staters[id].setListener(listener);
  }
}

export function unregisterListenerForStaterWithId(id: number) {
  if ((window as any).Staters[id]) {
    (window as any).Staters[id].unsetListener();
  }
}

// globally define Stater
if (!(window as any).Stater) {
  (window as any).Stater = Stater;
}

// create globally accessable pool of staters
if (!Array.isArray((window as any).Staters)) {
  (window as any).Staters = [];
}
