// import * as React from "react";
import MainframeConnector, {
  IFirebaseState,
} from "./toucan/src/firebase/mainframe";
import { store } from "./toucan/src/store";
import { useFirebaseUserState } from "./toucan/src/firebase/hooks";

MainframeConnector.on("change", (data) => {
  const { startTime, state } = data as IFirebaseState;
  store.dispatch({ type: "", startTime, state });
});

// start observing performance state once we have our offset
store.subscribe(() => {
  const { offset } = store.getState();
  if (offset !== undefined) {
    MainframeConnector.observeState();
  }
});

export function useInterfaceManager() {
  // const [toucanView, setToucanView] = React.useState("loading");

  // // listen to toucan's state
  // React.useEffect(() => {
  //   store.subscribe(() => {
  //     const { view } = store.getState();
  //     setToucanView(view);
  //   });
  // }, [setToucanView]);

  // return a user's interface when we're playing
  // and the user has a custom interface set
  const [userInterface] = useFirebaseUserState<string>(`interface`);
  // const isPlaying = toucanView === "playing";
  // const hasCustomInterface = userInterface !== null;
  return userInterface;
}
