import * as React from "react";
import GenericLayout from "./toucan/src/components/GenericLayout";
import { useWriteOnlyFirebaseUserState } from "./toucan/src/firebase/hooks";
import Button from "react-bootstrap/Button";
import { useInterpolatedString } from "./toucan/src/interpolate";
import InterfaceManager from "./toucan/src/interfaces/manager";

interface GateProps {
  text: string;
  buttonText: string;
  nextInterface: string;
}

const Gate = (props: GateProps) => {
  const nextInterface = useInterpolatedString(props.nextInterface);
  const setInterface = useWriteOnlyFirebaseUserState<string>("interface");

  const onClick = () => {
    if (nextInterface) {
      setInterface(nextInterface);
    }
  };

  React.useEffect(() => {
    InterfaceManager.notifyComponentChanged("gate");
  }, [props.text]);

  return (
    <GenericLayout
      mainText={
        <div>
          <p>{props.text}</p>
          <Button variant="success" onClick={onClick}>
            {props.buttonText}
          </Button>
        </div>
      }
    />
  );
};

export default Gate;
