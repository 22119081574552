import * as React from "react";
import { Button, Container, Header, PreviousButton } from "./Elements";

interface ResultProps {
  onAccept: () => void;
  onPrevious: () => void;
}

const Result = ({ onAccept, onPrevious }: ResultProps) => {
  return (
    <Container>
      <Header>{"save-answers"}</Header>
      <Button onClick={onAccept}>{"accept"}</Button>
      <PreviousButton onClick={onPrevious}>{"previous"}</PreviousButton>
    </Container>
  );
};

export default Result;
