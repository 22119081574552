import { Action } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { Tone } from "@smartphoneorchestra/live.js";
import * as Requests from "./requests";
import ToneSync from "./ToneSync";
import * as Utils from "./utils";

import WebSync from "./WebSync";

let countdownTimeout: number | null = null;

function clearCountdownTimeout() {
  if (countdownTimeout) {
    clearTimeout(countdownTimeout);
    countdownTimeout = null;
  }
}

function setPlayingOrCountdownState() {
  const playTime = ToneSync.getTimeSinceStart() / 1000;
  const shouldShowCountdown = playTime > -10;
  const isCounting = playTime < 0;
  if (!shouldShowCountdown) {
    store.dispatch({ type: "", view: "waiting" });
    countdownTimeout = window.setTimeout(setPlayingOrCountdownState, 50);
  } else if (!isCounting) {
    store.dispatch({ type: "", view: "playing" });
  } else {
    store.dispatch({ type: "", view: "countdown", countdownTime: playTime });
    countdownTimeout = window.setTimeout(setPlayingOrCountdownState, 50);
  }
}

export type AppView =
  | "countdown"
  | "ended"
  | "clapended"
  | "loading"
  | "playing"
  | "syncing"
  | "waiting";

export interface IAppState {
  view: AppView;
  percentage: number;
  startTime: number;
  part: number;
  amountOfParts: number;
  countdownTime: number;
  offset: number | undefined;
  state: string;
}

const initialState: IAppState = {
  view: "loading",
  percentage: 0,
  startTime: 0,
  part: 0,
  amountOfParts: 0,
  countdownTime: 10,
  offset: undefined,
  state: "waiting",
};

const reducer = (state = initialState, action: Action): IAppState => {
  return { ...state, ...action };
};

export const store = configureStore({ reducer });

// update countdown state on every action
let lastUpdatedState: string | undefined = undefined;
store.subscribe(() => {
  const currentState = store.getState();
  if (currentState.offset !== undefined) {
    if (lastUpdatedState === currentState.state) {
      return;
    }
    clearCountdownTimeout();
    lastUpdatedState = currentState.state;
    if (currentState.state === "started") {
      setPlayingOrCountdownState();
    } else if (currentState.state === "ended") {
      store.dispatch({ type: "", view: "clapended" });
    } else if (currentState.state === "waiting") {
      store.dispatch({ type: "", view: "waiting" });
    }
  }
});

export const actions = () => ({
  startSync() {
    store.dispatch({ type: "", view: "syncing" });
    WebSync((offset) => {
      ToneSync.setClockOffset(offset);
      store.dispatch({ type: "", offset });
    });
  },

  requestPart() {
    const requestedPart = Utils.getPartFromHash();
    Requests.requestComposition(requestedPart, (part, amountOfParts) => {
      store.dispatch({ type: "", part, amountOfParts });
      Tone.Buffer.loaded().then(() =>
        store.dispatch({ type: "", view: "ready" })
      );
    });
  },
});
