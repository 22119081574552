import * as React from "react";
import styled from "styled-components";

interface IConductorProps {
  bar: number;
  scene: string;
  bpm: number;
}

const Text = styled.div`
  font-weight: bold;
  font-size: 5vh;
`;

const BarNumber = styled.div`
  font-size: 15vh;
`;

const widowInnerHeight = window.innerHeight;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  height: ${widowInnerHeight}px;
  width: 100%;

  margin: 0.5rem 2rem;

  > div {
    color: #000;
    width: 100%;
    text-align: center;
    margin: 0px 0px 25px 0px;
    padding: 0.25rem;
  }
`;

const TheConductor = (props: IConductorProps) => {
  return (
    <Container>
      <Text>{`SCENE: ${props.scene}`}</Text>
      <BarNumber>{`BAR: ${props.bar}`}</BarNumber>
      <Text>{`BPM: ${props.bpm}`}</Text>
    </Container>
  );
};

export default TheConductor;
