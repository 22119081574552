import * as React from "react";
import styled from "styled-components";
import { useReadOnlyInterfaceDefinition } from "./toucan/src/firebase/hooks";

interface ITeamInfo {
  color: string;
  name: string;
  part: string;
}

interface ITeamView {
  view: string;
  fontSize: string;
}

const widowInnerHeight = window.innerHeight;

const Container = styled.div`
  height: ${widowInnerHeight}px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    border-radius: 20px;
    border: 5px solid;
    color: #000;
    width: 90%;
    height: 90%;
    text-align: center;
    font-weight: bold;
    margin: 25px;
    padding: 0.5rem;
  }
`;

const TeamName = styled.div`
  font-size: 6vh;
`;

const Part = styled.h1<{ size?: string }>`
  font-size: ${(props) => props.size || "30vh"};
  animation: blink 0.5s linear 9;

  @keyframes blink {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const TeamView = (props: ITeamView) => {
  const [teamInfo] = useReadOnlyInterfaceDefinition<ITeamInfo>(
    `theScore/teams/${props.view}`
  );

  return (
    <Container>
      <div
        style={{
          backgroundColor: teamInfo?.color,
        }}
      >
        {
          <React.Fragment>
            <TeamName>{teamInfo?.name}</TeamName>
            <Part key={teamInfo?.part} size={props.fontSize}>
              {teamInfo?.part}
            </Part>
          </React.Fragment>
        }
      </div>
    </Container>
  );
};

export default TeamView;
