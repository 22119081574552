const texts = [
  "We're sorry, but your browser is too old",
  "It would have a hard time keeping up with the rest",
  "And would probably sound horrible...",
  "So put your phone away and listen to the rest!",
  "Enjoy!",
];

let currentText = 0;

export function setPageToError() {
  document.body.className = "error";
  document.body.innerHTML = `<div class="screen"><div class="box"><div class="row header"><div class="logo"></div></div><div class="row content"><div class="wrapper"><div id="content-icon" class="icon"></div></div></div><div id="status" class="row footer"></div></div></div>`;
  setInterval(setText, 5000);
  setText();
}

function setText() {
  const element = document.getElementById("status");
  if (element) {
    element.innerHTML = texts[currentText];
    currentText = (currentText + 1) % texts.length;
  }
}
