import * as React from "react";
import Option from "./Option";
import * as Types from "../types";
import { Container, Header, PreviousButton } from "./Elements";

interface QuestionProps {
  question: Types.Question;
  showPrevious: boolean;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChoice: (choice: Types.Option) => void;
  onPrevious: () => void;
}

const Question = (props: QuestionProps) => {
  return (
    <Container>
      <Header>{props.question.question}</Header>
      {props.question.options.map((option, index) => (
        <Option key={`key-${index}`} option={option} onClick={props.onChoice} />
      ))}
      {props.showPrevious && (
        <PreviousButton onClick={props.onPrevious}>{"previous"}</PreviousButton>
      )}
    </Container>
  );
};

export default Question;
