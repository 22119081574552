import * as React from "react";

const redirectToAppOrWeb = (e: React.MouseEvent<HTMLDivElement>) => {
  e.preventDefault();
  const { url, app } = e.currentTarget.dataset;
  if (!url || !app) {
    return;
  }
  setTimeout(() => {
    window.open(url);
  }, 25);
  window.location.href = app;
};

const Social = () => (
  <div className="social">
    <div
      data-url="https://www.linkedin.com/company/91482521"
      data-app="https://www.linkedin.com/company/the-smartphone-orchestra/"
      onClick={redirectToAppOrWeb}
      className="social-btn linkedin"
    />
    <div
      data-url="https://www.instagram.com/smartphoneorchestra/"
      data-app="instagram://user?username=smartphoneorchestra"
      onClick={redirectToAppOrWeb}
      className="social-btn instagram"
    />
    <div
      data-url="https://facebook.com/smartphoneorchestra"
      data-app="fb://profile/1127859110590617"
      onClick={redirectToAppOrWeb}
      className="social-btn facebook"
    />
  </div>
);

export default Social;
