import { EventEmitter } from "eventemitter3";
import { initializeApp } from "firebase/app";
import * as database from "firebase/database";

export interface IFirebaseState {
  state: "waiting" | "started" | "ended";
  startTime: number;
}

const firebaseOptions = {
  apiKey: "AIzaSyC3y2cPL333KvSbcBuV9faTyClmjDjm2gg",
  databaseURL: "https://smartphone-orchestra.firebaseio.com/",
};

function getDomainName() {
  return location.hostname.replaceAll("www.", "").replaceAll(".", "_");
}

class MainframeConnector extends EventEmitter {
  private initialRefreshValueHasBeenReceived = false;
  private stateObserverRegistered = false;
  private database: database.Database;

  constructor() {
    super();

    const app = initializeApp(firebaseOptions, "mainframe");
    this.database = database.getDatabase(app);

    const refreshRef = database.child(this.root(), "refresh");
    database.onValue(refreshRef, () => {
      if (this.initialRefreshValueHasBeenReceived === false) {
        this.initialRefreshValueHasBeenReceived = true;
        return;
      }
      window.location.reload();
    });

    const userRef = database.push(database.child(this.root(), "activeUsers"));
    const connectedRef = database.ref(this.database, ".info/connected");
    database.onValue(connectedRef, () => {
      database.onDisconnect(userRef).remove();
      database.set(userRef, true);
    });
  }

  root() {
    return database.child(database.ref(this.database), getDomainName());
  }

  observeState() {
    if (this.stateObserverRegistered === false) {
      const stateRef = database.child(this.root(), "state");

      database.onValue(stateRef, (snapshot) => {
        const { startTime, state } = snapshot.val();
        this.emit("change", { startTime, state });
      });
      this.stateObserverRegistered = true;
    }
  }
}

export default new MainframeConnector();
