import * as React from "react";
import styled from "styled-components";
import { Textstate } from "./types";

interface ITextNotificationsProps {
  textState: Textstate;
}

const specialStates = {
  none: "",
  go: "GO!",
  drum: "DRUM SOLO!!!",
  piano: "PIANO SOLO!!!",
  crash: "MELTDOWN!!!",
};

const widowInnerHeight = window.innerHeight;

const Container = styled.div`
  height: ${widowInnerHeight}px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    border-radius: 20px;
    border: 5px solid;
    color: #000;
    width: 90%;
    height: 90%;
    text-align: center;
    font-weight: bold;
    margin: 25px;
    padding: 0.5rem;
  }
`;

const Text = styled.h1`
  font-size: 10vh;
  color: red;
  animation: blink 0.5s linear 5;

  @keyframes blink {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const TextNotifications = (props: ITextNotificationsProps) => {
  const textState = specialStates[props.textState];

  return (
    <Container>
      <div
        style={{
          backgroundColor: "white",
        }}
      >
        {<Text key={textState}>{textState}</Text>}
      </div>
    </Container>
  );
};

export default TextNotifications;
