import * as React from "react";
import styled from "styled-components";

interface ITeamInfo {
  color: string;
  name: string;
  part: string;
}

interface IScoreProps {
  teams: Record<string, ITeamInfo>;
  fontSize: string;
}

const TeamName = styled.div`
  font-weight: bold;
  font-size: 3vh;
`;

const Part = styled.h1<{ size?: string }>`
  font-size: ${(props) => props.size || "10vw"};
  animation: blink 0.5s linear 9;

  @keyframes blink {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const widowInnerHeight = window.innerHeight;

const Container = styled.div`
  display: flex;
  flex-direction: row column;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;

  height: ${widowInnerHeight}px;
  width: 100%;

  // margin: 1em;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    border-radius: 15px;
    border: 3.5px solid;
    color: #000;
    width: 40%;
    height: 40%;
    text-align: center;
    margin: 0px 0px 25px 0px;
    padding: 0.25rem;
  }
`;

const TheScore = (props: IScoreProps) => {
  const teamInfo = props.teams;

  return (
    <Container>
      {Object.keys(teamInfo).map((team, index) => (
        <div
          style={{
            backgroundColor: teamInfo[team].color,
          }}
          key={index}
        >
          {
            <React.Fragment>
              <TeamName>{teamInfo[team].name}</TeamName>
              <Part key={teamInfo[team].part} size={props.fontSize}>
                {teamInfo[team].part}
              </Part>
            </React.Fragment>
          }
        </div>
      ))}
    </Container>
  );
};

export default TheScore;
