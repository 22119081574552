import * as React from "react";
import * as database from "firebase/database";
import FirebaseImpl from ".";

export function useFirebaseLockObserver() {
  const [uuid, setUuid] = React.useState<string>("");
  const [lockId] = useFirebaseState(`users/${uuid}/lockId`);
  React.useEffect(() => {
    FirebaseImpl.addUuidListener((newUuid) => {
      setUuid(newUuid);
    });
  }, [setUuid]);
  return [lockId];
}

export function useDataSnapshotState<T>() {
  const [state, setState] = React.useState<T | null>(null);
  const setFromDataSnapshot = React.useCallback(
    (snapshot: database.DataSnapshot) => {
      setState(snapshot.val());
    },
    [setState]
  );
  return [state, setFromDataSnapshot] as [
    typeof state,
    typeof setFromDataSnapshot
  ];
}

// Hook to get and set state from any Firebase key. Using this hook will listen to
// changes in the realtime database and update its state when it changes remotely.
// Equivalent to React.useState, but now synchronised to Firebase.
function useFirebaseState<T>(key: string) {
  const [state, setState] = useDataSnapshotState<T>();
  const setFirebaseState = useSetFirebaseState<T>(key);

  React.useEffect(() => {
    const ref = database.child(FirebaseImpl.root(), key);
    database.onValue(ref, setState);
    return () => database.off(ref, "value", setState);
  }, [key, setState]);

  return [state, setFirebaseState] as [typeof state, typeof setFirebaseState];
}

function useSetFirebaseState<T>(key: string) {
  return React.useCallback(
    (s: T) => {
      database.set(database.child(FirebaseImpl.root(), key), s);
    },
    [key]
  );
}

export function useFirebaseTargetState<T>(key: string) {
  return useFirebaseState<T>(`${key}/${FirebaseImpl.uuid()}`);
}

export function useWriteOnlyFirebaseTargetState<T>(key: string) {
  return useSetFirebaseState<T>(`${key}/${FirebaseImpl.uuid()}`);
}

export function useFirebaseUserState<T>(key: string) {
  return useFirebaseState<T>(`users/${FirebaseImpl.uuid()}/${key}`);
}

export function useReadOnlyUserState<T>(key: string) {
  const [state] = useFirebaseState<T>(`users/${FirebaseImpl.uuid()}/${key}`);
  return [state];
}

export function useWriteOnlyFirebaseUserState<T>(key: string) {
  return useSetFirebaseState<T>(`users/${FirebaseImpl.uuid()}/${key}`);
}

export function useReadOnlyInterfaceDefinition<T>(key: string) {
  const [state] = useFirebaseState<T>(`interfaces/${key}`);
  return [state];
}

export function useReadOnlyDefinition<T>(key: string) {
  const [state] = useFirebaseState<T>(`${key}`);
  return [state];
}
