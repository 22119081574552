import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./toucan/src/store";
import FirebaseImpl from "./toucan/src/firebase";
import * as database from "firebase/database";
import { initializeApp } from "firebase/app";

import "./toucan/src/stylesheets/main.scss";
import { createGlobalStyle } from "styled-components";

import "bootstrap/dist/css/bootstrap.min.css";

import "./toucan/src/devices/stater";
import { Tone } from "@smartphoneorchestra/live.js";

import InterfaceManager from "./toucan/src/interfaces/manager";
import Action from "./Action";
import Questions from "./Questions";
import Color from "./Color";
import Image from "./Image";
import VoteButtons from "./VoteButtons";
import TextSlider from "./TextSlider";
import Gate from "./Gate";
import Number from "./Number";
import Text from "./toucan/src/interfaces/Text";
import ImageGate from "./ImageGate";
import SoundText from "./SoundText";
import TheScore from "./TheScore";
import TheConductor from "./theConductor";
import TeamView from "./TeamView";

// import { playRandomNotificationSound } from "./sound";

const firebaseOptions = {
  apiKey: "AIzaSyBxwZijJcv-TPjr3ePOEasyPD2jojcYH58",
  databaseURL:
    "https://games-in-concert-default-rtdb.europe-west1.firebasedatabase.app/", // Realtime Database
  localStorageUUID: "games-in-concert",
};

FirebaseImpl.initialize(firebaseOptions);

const app = initializeApp(firebaseOptions, "gic");
const connectedRef = database.ref(database.getDatabase(app), ".info/connected");
database.onValue(connectedRef, () => {
  database.onDisconnect(FirebaseImpl.userReference()).remove();
});

InterfaceManager.registerInterface("action", Action);
InterfaceManager.registerInterface("questions", Questions);
InterfaceManager.registerInterface("color", Color);
InterfaceManager.registerInterface("image", Image);
InterfaceManager.registerInterface("votebuttons", VoteButtons);
InterfaceManager.registerInterface("textslider", TextSlider);
InterfaceManager.registerInterface("gate", Gate);
InterfaceManager.registerInterface("text", Text);
InterfaceManager.registerInterface("number", Number);
InterfaceManager.registerInterface("imagegate", ImageGate);
InterfaceManager.registerInterface("soundtext", SoundText);
InterfaceManager.registerInterface("thescore", TheScore);
InterfaceManager.registerInterface("theconductor", TheConductor);
InterfaceManager.registerInterface("teamview", TeamView);

// InterfaceManager.setOnComponentChangedCallback(() => {
//   playRandomNotificationSound();
// });

Tone.Transport.PPQ = 48;

const GlobalStyle = createGlobalStyle`
  body {
    width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
    position: fixed;
  }
  #root {
    width: 100%;
    height: 100%;
  }
`;

const SuspenseFallback = () => (
  <div className="d-flex justify-content-center">
    <div className="spinner-grow text-dark" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<SuspenseFallback />}>
      <Provider store={store}>
        <GlobalStyle />
        <App />
      </Provider>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
document
  .getElementsByTagName("body")[0]!
  .addEventListener("click", async () => {
    await Tone.start();
  });
