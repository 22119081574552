import * as React from "react";
import styled from "styled-components";

interface INotificationProps {
  text?: string;
}

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: red;
  color: white;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 100;
  z-index: 999;
  padding: 0.5rem 0;
`;

const Notification = (props: INotificationProps) => {
  return <Container>{props.text}</Container>;
};

export default Notification;
