import * as React from "react";
import AudioReactiveCanvas from "../components/AudioReactiveCanvas";
import ThumbText from "../components/ThumbText";

const Playing = () => (
  <AudioReactiveCanvas>
    <ThumbText />
  </AudioReactiveCanvas>
);

export default Playing;
