import * as React from "react";
import { useTranslation } from "react-i18next";
import GenericLayout from "../components/GenericLayout";

const Syncing = () => {
  const { t } = useTranslation("toucan");
  return (
    <GenericLayout
      contentClass="icon"
      footerText={<span>{t("synchronising")}</span>}
    />
  );
};

export default Syncing;
