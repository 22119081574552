export default function (callback: (offset: number) => void) {
  const server = "wss://spo-metronome.appspot.com/";
  const connection = new WebSocket(server, "echo-protocol");
  connection.onmessage = (e: MessageEvent) => {
    const { type, ...data } = JSON.parse(e.data);
    if (type === "ping") {
      const { serverTime } = data;
      const reply = { type: "pong", clientTime: Date.now(), serverTime };
      connection.send(JSON.stringify(reply));
    } else if (type === "offset") {
      callback(data.offset);
    }
  };
}
