import { Tone } from "@smartphoneorchestra/live.js";
import * as Live from "@smartphoneorchestra/live.js";
import * as SupportError from "./supportError";

const anyWindow = window as any;
anyWindow.Live = Live;
anyWindow.Tone = Tone;

type RequestCallback = (part: number, amountParts: number) => void;

let compositionUrl = "//conductor.smartphoneorchestra.com/set";
export function setCompositionUrl(url: string) {
  compositionUrl = url;
}

export function requestComposition(
  part: string | undefined,
  callback: RequestCallback
) {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", compositionUrl);
  if (part) {
    xhr.setRequestHeader("Part", part);
  }
  xhr.onload = () => {
    if (xhr.status !== 200) {
      console.error("Request failed. Returned status of " + xhr.status);
      return;
    }

    try {
      // eslint-disable-next-line
			eval(xhr.responseText) // i'm sorry
    } catch (e) {
      console.error(e);
      setTimeout(SupportError.setPageToError, 1000);
    }
    const partHeader = xhr.getResponseHeader("Part");
    const receivedPart = parseInt(partHeader || "", 10);
    const amountHeader = xhr.getResponseHeader("Amount-Parts");
    const amountParts = parseInt(amountHeader || "", 10);
    callback(receivedPart, amountParts);
  };
  xhr.send();
}
