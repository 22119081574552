import * as React from "react";
import GenericLayout from "../components/GenericLayout";
import { useInterpolatedString } from "../interpolate";
import InterfaceManager from "./manager";

interface ITextProps {
  text: string;
}

const Text = (props: ITextProps) => {
  const text = useInterpolatedString(props.text);
  React.useEffect(() => {
    InterfaceManager.notifyComponentChanged("text");
  }, [text]);
  return <GenericLayout contentClass="text" mainText={<h1>{text}</h1>} />;
};

InterfaceManager.registerInterface("text", Text);

export default Text;
