import React from "react";
import GenericLayout from "../components/GenericLayout";

interface IErrorProps {
  error: string;
}

const ErrorComponent = (props: IErrorProps) => (
  <GenericLayout mainText={props.error} />
);

export default ErrorComponent;
