import * as React from "react";
import { useInterpolatedString } from "./toucan/src/interpolate";
import GenericLayout from "./toucan/src/components/GenericLayout";
import styled from "styled-components";
import { playCustomSample } from "./sound";

interface CustomSoundTextProps {
  text: string;
  sample: string;
  vol: number;
}

const Header = styled.h1`
  font-size: 3rem;
`;

const SoundText = (props: CustomSoundTextProps) => {
  const text = useInterpolatedString(props.text);
  const sample = props.sample;
  const volume = props.vol;

  React.useEffect(() => {
    playCustomSample(sample, volume);
  }, [text, sample]);

  return (
    <GenericLayout
      contentClass="text"
      mainText={
        <div>
          <Header>{text}</Header>
        </div>
      }
    />
  );
};

export default SoundText;
