import * as React from "react";
import { useTranslation } from "react-i18next";

interface IThumbTextProps {
  text?: string;
}

const requestUserActionTime = 25000;

function useReminder(callback: () => void) {
  const savedTimeout = React.useRef(-1);

  const request = React.useCallback(() => {
    clearTimeout(savedTimeout.current);
    savedTimeout.current = window.setTimeout(callback, requestUserActionTime);
  }, [savedTimeout, callback]);

  // Remember the latest callback.
  React.useEffect(() => {
    request();
    return () => clearTimeout(savedTimeout.current);
  }, [request]);

  return request;
}

const ThumbText = (props: IThumbTextProps) => {
  const { t } = useTranslation("toucan");
  const [showButton, setShowButton] = React.useState(false);
  const requestReminder = useReminder(() => setShowButton(true));

  const toggleThumb = () => {
    requestReminder();
    setShowButton(false);
  };

  const showClass = showButton ? "show" : "hide";
  return (
    <div className="screen">
      <div className="instruction-text">{props.text}</div>
      <div>
        <div
          onClick={toggleThumb}
          onTouchStart={toggleThumb}
          onTouchEnd={toggleThumb}
          className={`awake-button ${showClass}`}
        />
        <div className={`awake-text ${showClass}`}>{t("keep-awake")}</div>
      </div>
    </div>
  );
};

export default ThumbText;
