import * as React from "react";
import { useTranslation } from "react-i18next";
import Social from "../components/Social";
import GenericLayout from "../components/GenericLayout";

const Ended = () => {
  const { t } = useTranslation("toucan");
  return (
    <GenericLayout
      contentClass="text"
      mainText={<h1>{t("you-were-awesome")}</h1>}
      footerText={<Social />}
    />
  );
};

export default Ended;
