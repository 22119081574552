import * as React from "react";
import ComponentRenderer from "./toucan/src/interfaces";
import { useInterfaceManager } from "./InterfaceManager";
import TextNotifications from "./TextNotifications";
import {
  useWriteOnlyFirebaseUserState,
  useReadOnlyDefinition,
} from "./toucan/src/firebase/hooks";
import { Textstate } from "./types";
import Notification from "./Notification";

const App = () => {
  const currentInterface = useInterfaceManager();
  const url = window.location.href;
  const urlExt = url.slice(url.lastIndexOf("/") + 1) || "theScore";
  const setOwnInterface = useWriteOnlyFirebaseUserState<string>("interface");
  const [gameAppState] = useReadOnlyDefinition<string>("gameAppState");
  const [game2SpecialState] =
    useReadOnlyDefinition<Textstate>("game2SpecialState");
  const [isOnline, setIsOnline] = React.useState(navigator.onLine);

  React.useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  React.useEffect(() => {
    setOwnInterface(urlExt);
  }, [urlExt]);

  React.useEffect(() => {
    const portrait = window.matchMedia("(orientation: portrait)");

    portrait.addEventListener("change", function () {
      window.location.reload();
    });
  }, []);

  React.useEffect(() => {
    document.body.className = currentInterface || "";
  }, [currentInterface]);

  if (gameAppState === "game2_start" || gameAppState === "game2_fence_down") {
    return (
      <React.Fragment>
        {!isOnline && <Notification text={"No internet connection..."} />}
        {
          <TextNotifications
            textState={game2SpecialState || "none"}
          ></TextNotifications>
        }
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {!isOnline && <Notification text={"No internet connection..."} />}
      {<ComponentRenderer interface={currentInterface} />};
    </React.Fragment>
  );
};

export default App;
