import * as React from "react";

export function getPartFromHash() {
  // Override random number by hash in url
  if (window.location.hash) {
    const hashLessString = window.location.hash.substring(1);
    if (hashLessString) {
      console.log("Random number overwritten by URL hash.");
      return hashLessString;
    }
  }
  return undefined;
}

export function isAndroid() {
  return navigator.userAgent.match(/Android/i) !== null;
}

export function isFirefox() {
  return navigator.userAgent.match(/Firefox/i) !== null;
}

export function randomElement(arr: any[]) {
  const index = Math.floor(Math.random() * arr.length);
  return arr[index];
}

export function average(arr: number[]) {
  const sum = arr.reduce((pv, cv) => pv + cv, 0);
  return sum / arr.length;
}

export class Onebang {
  private state: boolean;
  left: () => void;
  right: () => void;

  constructor(callback: () => void) {
    this.state = false;
    this.left = function () {
      if (this.state === false) {
        callback();
      }
      this.state = true;
    };

    this.right = function () {
      this.state = false;
    };
  }
}

export function useInterval(callback: () => void, delay: number) {
  const savedCallback = React.useRef<() => void>();

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function lowPass(input: number, output: number) {
  return output + 0.5 * (input - output);
}
