import * as React from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../store";

const Countdown = () => {
  const countdownTime = useSelector((s: IAppState) => s.countdownTime);
  const seconds = Math.min(-Math.floor(countdownTime), 10);
  return (
    <div className="screen">
      <div className="box">
        <div className="row content">
          <div className="wrapper">
            <div className="text">{seconds}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Countdown;
