import AudioEffect from "@smartphoneorchestra/live.js/build/effects/audio/AudioEffect";
import { Tone } from "@smartphoneorchestra/live.js";

class Analyser extends AudioEffect {
  private analyser = new Tone.Analyser("waveform", 512);

  input() {
    return this.analyser;
  }

  output() {
    return this.analyser;
  }

  set smoothing(value: number) {
    this.analyser.smoothing = value;
  }

  getValue() {
    return this.analyser.getValue();
  }
}

export default Analyser;
