import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IAppState } from "../store";
import GenericLayout from "../components/GenericLayout";

type ILoadingProps = ReturnType<typeof mapStateToProps>;

const Loading = ({ percentage }: ILoadingProps) => {
  const { t } = useTranslation("toucan");
  return (
    <GenericLayout
      contentClass="text"
      mainText={`${percentage.toFixed(0)}%`}
      footerText={<span>{t("loading")}</span>}
    />
  );
};

const mapStateToProps = (state: IAppState) => {
  const { percentage } = state;
  return { percentage };
};

export default connect(mapStateToProps)(Loading);
