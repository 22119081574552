import { initializeApp } from "firebase/app";
import * as database from "firebase/database";

interface FirebaseConfig {
  apiKey: string;
  databaseURL: string;
  localStorageUUID: string;
}

const localStorageLockIdKey = "spo-lock-id";

type UserIdChangedCallback = (uuid: string) => void;

class FirebaseImplementation {
  // the uuid is the key of the user in the 'users' key
  private firebaseUuid = "";
  private localStorageUUIDKey = "";

  // the lock id is an unique id to ensure that only one client
  // is currently assigned to a uuid
  private firebaseLockId = "";

  private uuidChangedCallbacks: UserIdChangedCallback[] = [];

  public initialize(config: FirebaseConfig) {
    initializeApp({
      apiKey: config.apiKey,
      databaseURL: config.databaseURL,
    });

    this.localStorageUUIDKey = config.localStorageUUID;
    this.setUuid(this.recoverUuid());
    this.firebaseLockId = this.recoverLockId();
  }

  public root() {
    return database.ref(database.getDatabase());
  }

  public userReference() {
    return database.child(this.root(), `users/${this.firebaseUuid}`);
  }

  public addUuidListener(cb: UserIdChangedCallback) {
    this.uuidChangedCallbacks.push(cb);
    cb(this.firebaseUuid);
  }

  public uuid() {
    return this.firebaseUuid;
  }

  public setUuid(uuid: string) {
    this.firebaseUuid = uuid;
    localStorage.setItem(this.localStorageUUIDKey, uuid);
    for (const cb of this.uuidChangedCallbacks) {
      cb(uuid);
    }
  }

  public lockId() {
    return this.firebaseLockId;
  }

  private recoverUuid() {
    const savedUuid = localStorage.getItem(this.localStorageUUIDKey);
    if (savedUuid) {
      console.log(`Recovered UUID ${savedUuid}`);
      return savedUuid;
    } else {
      const ref = database.push(this.root());
      if (ref.key === null) {
        console.error(`Ref is null!`);
        return "";
      } else {
        localStorage.setItem(this.localStorageUUIDKey, ref.key);
      }
      return ref.key;
    }
  }

  private recoverLockId() {
    const saved = localStorage.getItem(localStorageLockIdKey);
    if (saved) {
      return saved;
    } else {
      const id = Math.random().toString(20).substr(2);
      localStorage.setItem(localStorageLockIdKey, id);
      return id;
    }
  }
}

export default new FirebaseImplementation();
