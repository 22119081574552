import * as React from "react";
import Manager from "./manager";
import ErrorComponent from "./Error";
import { useReadOnlyInterfaceDefinition } from "../firebase/hooks";

interface IComponentRendererProps {
  interface: string | null;
}

interface Interface {
  type: string;
}

const ComponentRenderer = (props: IComponentRendererProps) => {
  // look up the definition of the user's interface. This can be null when the interface
  // value is bogus or a Fixed Component
  const [definition] = useReadOnlyInterfaceDefinition<Interface>(
    props.interface || ""
  );

  if (props.interface === null) {
    return null;
  }

  // check Fixed Component registry for the interface, and render if it exists.
  if (Manager.hasFixedComponent(props.interface)) {
    const { Component: IComponent } = Manager.getFixedComponent(
      props.interface
    );
    return <IComponent />;
  }

  // if the interface is not a Fixed Component we need a valid definition
  // if we don't have one, render an error.
  if (!definition) {
    const error = `No definition for interface '${props.interface}'`;
    return <ErrorComponent {...{ error }} />;
  }

  const { type, ...rest } = definition;

  // check if the interface type in the definition exists in our
  // Dynamic Components registry. if not, render an error.
  if (!Manager.hasDynamicComponent(type)) {
    const error = `Unknown interface type ${type}`;
    return <ErrorComponent {...{ error }} />;
  }

  const { requiredProps, Component } = Manager.getDynamicComponent(type);

  // check if all requiredProps are in the definition. if not, render an error
  const missingProps = (requiredProps || []).filter((p) => !(p in definition));
  if (missingProps.length) {
    const error = `Props ${missingProps.concat()} missing in component ${type}`;
    return <ErrorComponent {...{ error }} />;
  }

  // render the Dynamic Component
  return <Component {...rest} />;
};

export default ComponentRenderer;
