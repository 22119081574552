import * as React from "react";
import { connect } from "react-redux";
import { IAppState } from "../store";

import * as Live from "@smartphoneorchestra/live.js";
import Analyser from "../devices/analyser";

const partColors = [
  [86, 7, 245], // blue
  [232, 44, 12], // red
  [255, 13, 255], // purple
  [255, 228, 62], // yellow
  [73, 232, 22], // green
  [255, 83, 13], // orange
  [14, 230, 255], // light blue
  [232, 6, 150], // pink
  [245, 199, 0], // dark yellow
  [212, 255, 0], // gif groen
  [255, 255, 255],
  [255, 255, 255],
];

interface IAudioReactiveCanvasProps extends ReturnType<typeof mapStateToProps> {
  children?: React.ReactNode;
}

class AudioReactiveCanvas extends React.Component<IAudioReactiveCanvasProps> {
  private canvas: HTMLCanvasElement | null = null;
  private renderContext: CanvasRenderingContext2D | null = null;
  private color = [25, 25, 25];
  private meter: Analyser | null = null;
  private shouldDrawCanvas = false;
  private timeBetweenFrames = 33;
  private timeLastFrame = 0;

  componentDidMount() {
    this.color = partColors[this.props.part - 1] || [255, 255, 255];
    this.meter = new Analyser();
    this.meter.smoothing = 0.9;
    Live.LiveState.Master.insert(this.meter);

    this.canvas = document.getElementById("animation") as HTMLCanvasElement;
    this.renderContext = this.canvas.getContext("2d");

    this.shouldDrawCanvas = true;
    requestAnimationFrame(this.drawCanvas);
  }

  componentWillUnmount() {
    this.shouldDrawCanvas = false;
  }

  drawCanvas = () => {
    // break requestAnimationFrame loop when canvas should not be drawn
    if (this.shouldDrawCanvas === false) {
      return;
    }

    if (
      this.canvas === null ||
      this.renderContext === null ||
      this.meter === null
    ) {
      return;
    }

    const now = Date.now();
    const timeSinceLastFrame = now - this.timeLastFrame;

    if (timeSinceLastFrame > this.timeBetweenFrames) {
      this.canvas.width = window.innerWidth;
      this.canvas.height = window.innerHeight;
      // modulate color with amplitude
      const values = Math.max.apply(null, this.meter.getValue() as any);
      const v = Math.abs(values) * 3;
      const colorAddition = Math.min(0.3 + v, 1);
      const r = Math.floor(this.color[0] * colorAddition);
      const g = Math.floor(this.color[1] * colorAddition);
      const b = Math.floor(this.color[2] * colorAddition);

      this.renderContext.beginPath();
      this.renderContext.rect(0, 0, this.canvas.width, this.canvas.height);
      this.renderContext.fillStyle = `rgb(${r},${g},${b})`;
      this.renderContext.globalAlpha = 1.0;
      this.renderContext.fill();

      this.timeLastFrame = now;
    }
    requestAnimationFrame(this.drawCanvas);
  };

  render() {
    return (
      <div>
        <canvas id="animation" />
        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  const { part } = state;
  return { part };
};

export default connect(mapStateToProps)(AudioReactiveCanvas);
