import * as React from "react";
import { useTranslation } from "react-i18next";
import Social from "../components/Social";
import GenericLayout from "../components/GenericLayout";
import { isAndroid, Onebang, randomElement, lowPass } from "../utils";
import { Tone } from "@smartphoneorchestra/live.js";

let currentValue = 0;

const handclapPlayers = [
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/klappen/Klap_1-Lang.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/klappen/Klap_2-Lang.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/klappen/Klap_3-Lang.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/klappen/Klap_4-Lang.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/klappen/Klap_5-Lang.mp3"
  ).toDestination(),
  new Tone.Player(
    "https://s3-eu-west-1.amazonaws.com/smartphone-orchestra/klappen/Klap_6-Lang.mp3"
  ).toDestination(),
];

const playHandclampSound = () => {
  const randomPlayer = randomElement(handclapPlayers);
  randomPlayer.start();
};

const oneBang = new Onebang(playHandclampSound);

const deviceMotionEvent = (e: DeviceMotionEvent) => {
  if (e.acceleration) {
    e.preventDefault();
    // some devices don't have the necessary sensors and return null
    const x = Math.abs(e.acceleration.x || 0);
    currentValue = lowPass(x, currentValue);
    if (currentValue > 20) {
      oneBang.left();
    } else {
      oneBang.right();
    }
  }
};

const simpleEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  e.preventDefault();
  oneBang.left();
  oneBang.right();
};

const iPhoneClap = () => (
  <div onClick={simpleEvent}>
    <span style={{ fontSize: "4rem" }} role="img" aria-label="clap">
      👏
    </span>
    <h1>Tap for a clap!</h1>
  </div>
);

const AndroidClap = (t: any) => <h1>{t("shake-your-phone")}</h1>;

const ClapEnded = () => {
  const { t } = useTranslation("toucan");

  React.useEffect(() => {
    window.addEventListener("devicemotion", deviceMotionEvent);
    return () => {
      window.removeEventListener("devicemotion", deviceMotionEvent);
    };
  }, []);

  const clapElement = isAndroid() ? AndroidClap(t) : iPhoneClap();
  return (
    <GenericLayout
      contentClass="text"
      mainText={clapElement}
      footerText={<Social />}
      onClick={simpleEvent}
    />
  );
};

export default ClapEnded;
