import { useFirebaseUserState } from "./firebase/hooks";

const expr = new RegExp(/\${.*?}/g);

function useAvailableVariables() {
  const [user] = useFirebaseUserState("");
  return { user: user || {} } as Record<string, any>;
}

function variableNameToValue<T>(
  str: string,
  variables: Record<string, any>,
  fallback: T
) {
  try {
    const fn = str.replace(/[${|}]/g, "");
    // eslint-disable-next-line no-new-func
    return new Function(`return ${fn};`).call(variables);
  } catch (e) {
    return fallback;
  }
}

export function useVariable<T>(value: T | string | undefined, fallback: T): T {
  const variables = useAvailableVariables();
  if (value === undefined) {
    return fallback;
  } else if (typeof value === "string") {
    const matches = value.match(expr) || [];
    if (matches.length === 1) {
      return variableNameToValue<T>(matches[0], variables, fallback);
    }
    return fallback;
  }
  return value;
}

export function useInterpolatedString(str: string): string {
  const safestring = str || "";
  const matches = safestring.match(expr) || [];
  const variables = useAvailableVariables();
  const replacements = matches.map((match) => {
    return variableNameToValue(match, variables, "");
  });
  return replacements.reduce<string>((pv, cv, idx) => {
    return pv.replace(matches[idx], cv);
  }, str);
}
