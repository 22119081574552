import * as React from "react";

interface IGenericLayoutProps {
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  contentClass?: string;
  mainText?: string | React.ReactChild;
  footerText?: string | React.ReactChild;
  style?: React.CSSProperties;
}

const GenericLayout = ({
  onClick,
  style = {},
  contentClass = "text",
  mainText = undefined,
  footerText = undefined,
}: IGenericLayoutProps) => (
  <div className="screen" onClick={onClick} style={style}>
    <div className="box">
      <div className="row header">
        <div className="logo" />
      </div>
      <div className="row content">
        <div className="wrapper">
          <div className={contentClass}>{mainText}</div>
        </div>
      </div>
      <div className="row footer">{footerText}</div>
    </div>
  </div>
);

export default GenericLayout;
