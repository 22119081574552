import * as React from "react";
import Countdown from "./Countdown";
import Ended from "./Ended";
import ClapEnded from "./ClapEnded";
import Loading from "./Loading";
import Playing from "./Playing";
import Ready from "./Ready";
import Syncing from "./Syncing";
import Waiting from "./Waiting";

export interface IInterfaceComponent {
  requiredProps?: string[];
  Component: React.JSXElementConstructor<any>;
}

export interface IRegistry {
  [name: string]: IInterfaceComponent;
}

export type OnComponentChanged = (name: string) => void;

export class InterfaceManager {
  // Fixed Components are components that can directly be rendered by
  // the 'interface' key in an user's Firebase state.
  // So, from the Max patch we call 'interface waitforinstructions',
  // and that component will be rendered.
  // We use these 'hardcoded' interfaces for commonly used interfaces
  // where creating a dynamic interface feels a bit cumbersome.
  private fixedComponents: IRegistry = {
    countdown: {
      Component: Countdown,
    },
    ended: {
      Component: Ended,
    },
    clapended: {
      Component: ClapEnded,
    },
    loading: {
      Component: Loading,
    },
    playing: {
      Component: Playing,
    },
    ready: {
      Component: Ready,
    },
    syncing: {
      Component: Syncing,
    },
    waiting: {
      Component: Waiting,
    },
  };

  // Dynamic Components are defined at runtime using the 'interfaces'
  // object in Firebase. These definitions reference the 'type'. requiredProps
  // can also be defined to prevent rendering when they are missing.
  // A definition's name can be used as the value of a client's 'interface'
  // key in Firebase. If this value does not exist as a Fixed Component in
  // registry above, we end up looking for its definition in Firebase
  // and render the necessary component by looking up this registry.
  private dynamicComponents: IRegistry = {};

  private onComponentChangedCallback: OnComponentChanged = () => {
    /**/
  };

  public setOnComponentChangedCallback(callback: OnComponentChanged) {
    this.onComponentChangedCallback = callback;
  }

  public notifyComponentChanged(name: string) {
    this.onComponentChangedCallback(name);
  }

  public overrideFixedComponent(
    name: string,
    component: React.JSXElementConstructor<any>
  ) {
    if (!this.hasFixedComponent(name)) {
      throw `Cannot override component "${name}" because there is no fixed component with the same name`;
    }
    this.fixedComponents[name] = { Component: component };
  }

  public registerInterface(
    name: string,
    component: React.JSXElementConstructor<any>
  ) {
    if (this.hasFixedComponent(name)) {
      throw `Cannot register component "${name}" because there exists a fixed component with the same name`;
    }
    this.dynamicComponents[name] = { Component: component };
  }

  public hasFixedComponent(name: string) {
    return name in this.fixedComponents;
  }

  public getFixedComponent(name: string) {
    return this.fixedComponents[name];
  }

  public hasDynamicComponent(name: string) {
    return name in this.dynamicComponents;
  }

  public getDynamicComponent(name: string) {
    return this.dynamicComponents[name];
  }
}

export default new InterfaceManager();
